<template>
  <div class="blayoutMind">
    <div class="blayoutMindLeft" 
      :style="{ width: showCollapse == false ? '12%' : '64px' }"
    >
      <div class="blayoutMindLeftTop">
        <div><img class="blayoutImg" src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111162431_logoColor.png"></div>
        <div>安徽英制马力</div>
      </div>
      <!-- 商品商户 -->
      <div class="blayoutMindLeftNext">
        <el-menu
          default-active="0"
          :collapse="showCollapse"
          :collapse-transition="false"
          class="el-menu-vertical-demo"
          background-color="#304156"
          text-color="#bfcbd9"
        >
          <el-sub-menu v-for="(item,n) in menu" :key="item.id" :index="n">
             <template #title>
              <span>{{item.name}}</span>
            </template>
              <el-menu-item 
                v-for="(i,s) in item.list" 
                :key="i.id" 
                @click="homeClick(item,i)"
                :index="n+'-'+s">{{i.lable}}</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </div>
    </div>
    <div class="blayoutMindRight">
      <div class="blayoutMindRightTop">
        <div class="blayoutMindRightTopLeft" @click="shousuoClick">
          <img class="shousuoclass" src="../../assets/shousuo.png" alt="">
        </div>
        <div class="blayoutMindRightTopMiddle">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>{{breadMenu}}</el-breadcrumb-item>
            <el-breadcrumb-item>{{breadSon}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="blayoutMindRightTopRight">
          <div>
            <el-popover v-model="visible" placement="bottom" :width="160">
              <div class="signOut" @click="confirmClick">退出登录</div>
              <div class="signOut" @click="inforClick">个人信息</div>
              <template #reference>
                <img @click="imgClick" class="blayoutUserImg" :src="avatar" alt="">
              </template>
            </el-popover>
          </div>
          <div>用户名：{{userName}}</div>
        </div>
      </div>
      <div class="blayoutBody">
        <router-view></router-view>
      </div>
    </div>
  </div>

  <el-dialog 
    v-model="dialogFormVisible" 
    title="个人信息" 
    width="500px"
    @closed="closedDialog"
   >
    <el-form 
      :model="form"
      label-position="left"
      label-width="80px"
    >
      <el-form-item label="头像">
        <img class="blayoutDialogUserImg" :src="avatar" alt="">
      </el-form-item>
      <el-form-item label="用户名称">
        <div>{{userName}}</div>
      </el-form-item>
      <el-form-item label="原密码">
        <el-input type="password" v-model="form.oldPwd"/>
      </el-form-item>
      <el-form-item label="新密码">
        <el-input type="password" v-model="form.newPwd"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="addModifyClick">修改</el-button>
      </span>
    </template>
  </el-dialog>  
</template>

<script>
export default {
  name: 'blayout',
  data() {
    return {
      visible:false,
      menu:[
        {id:0,name:'网站管理',list:[
            {id:0,path:'bhome',lable:'网站首页'}
        ]},
        {id:1,name:'商品管理',list:[
            {id:0,path:'bcommodity',lable:'商品管理'},
            {id:1,path:'bcategory',lable:'商品类别'},
        ]},
        {id:2,name:'订单管理',list:[
            {id:0,path:'border',lable:'订单管理'}
        ]},
      ],
      showMerchantSwitch:"商品商户",
      userName:"管理员",
      avatar:"https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111142521_userTou.png",
      showCollapse:false,
      breadMenu:"网站管理",
      breadSon:"网站首页",

      dialogFormVisible:false,
      form:{},
    };
  },
  created(){
    this.$router.push({path:'/bhome'})
  },
  methods:{
    imgClick(){
      this.visible = !this.visible
    },
    confirmClick(){
      this.$router.push({path:'/layout'});
      sessionStorage.clear()
    },
    homeClick(res,item){
      this.$router.push({path:'/'+item.path})
      this.breadMenu = res.name
      this.breadSon = item.lable
    },
    shousuoClick(){
      this.showCollapse = !this.showCollapse
    },
    inforClick(){
      this.dialogFormVisible=true
    },
    closedDialog(){
      this.form={}
    },
    addModifyClick(){
    }
  }
};
</script>

<style>
*{
    margin: 0px;
    padding: 0px;
}
.blayoutMind {
  width: 100vw;
  height: 100vh;
  display: flex;
  box-sizing: border-box;
}
.blayoutMindLeft{
  width: 12%;
  height: 100%;
  box-sizing: border-box;
  background-color: #304156;
  transition: width 1s;
}
.blayoutMindLeftTop{
  width: 100%;
  height: 40px;
  padding: 0px 10px;
  box-sizing: border-box;
  font-size: 18px;
  color: white;
  display: flex;
  align-items: center;
}
.blayoutImg{
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.blayoutDialogUserImg{
  width: 80px;
  height: 80px;
}
.blayoutMindLeftNext{
  width: 100%;
  height: calc(100vh - 40px);
}
.el-menu{
  border-right:none;
}
.menuImg{
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.blayoutMindRight{
  width: 98%;
  height: 100%;
  box-sizing: border-box;
  background-color: #ECEEF6;
}
.blayoutMindRightTop{
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.blayoutMindRightTopLeft{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shousuoclass{
  width: 25px;
  height: 25px;
}
.blayoutMindRightTopMiddle{
  width: 1200px;
}
.blayoutMindRightTopRight{
  min-width: 190px;
  height: 40px;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  /* color: white; */
  font-size: 17px;
}
.signOut{
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
}
.signOut:hover{
  cursor: pointer;
}
.blayoutUserImg{
  width: 34px;
  height: 34px;
  border-radius: 17px;
  margin-right: 10px;
}
.blayoutBody{
  width: 100%;
  height: calc(100Vh - 40px);
}
</style>